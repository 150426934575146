
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        







































































































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.switcher,
[class*='switcher--'] {
  position: relative;
  display: inline-flex;
  background: $white;
  border: 0.1rem solid $c-blue-light-pale;
  border-radius: 5px;

  &.is-disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &.is-hidden {
    display: none;
  }
}

.switcher__slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  height: 100%;
  background: $c-anthracite-light;
  border-radius: 5px;
  opacity: 0;
  transform: scale(1.05, 1.2);
}

.switcher__option {
  position: relative;
  z-index: 10;
  flex-shrink: 0;
  min-width: 10rem;
  padding: $spacing * 0.5 $spacing;
  color: $c-blue-dark;
  line-height: 1;
  background: transparent;
  border: 0;

  // &:not(:last-child) {
  //   border-right: .1rem solid $c-blue-light-pale;
  // }

  &.is-active {
    color: $white;
    pointer-events: none;
  }

  @include mq(xxxl) {
    padding: $spacing * 0.5 $spacing * 1.5;
  }
}

.switcher__option__logo {
  display: block;
  // width: auto;
  max-width: 5rem;
  height: 1.8rem;
  fill: $c-anthracite-light;
  transition: fill 0.3s $ease;

  .switcher__option.is-active & {
    fill: $white;
  }

  @include mq(m) {
    max-width: 8rem;
    height: 2.4rem;
  }
}

.switcher__option__label {
  @extend %fw-bold;

  color: $c-anthracite-light;
  font-family: $ff-alt;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s $ease;

  .switcher__option.is-active & {
    color: $white !important;
  }

  @include mq(m) {
    font-size: 1.2rem;
  }

  @include mq(l) {
    font-size: 1.6rem;
  }

  @include mq(xxl) {
    font-size: 1.6rem;
  }

  .no-price-difference & {
    strong {
      font-size: 2.4rem !important;
    }

    small {
      display: none !important;
      // opacity: 0 !important;
    }
  }
}
